<template>
  <div class="login">
    <v-row align="center" justify="center" class="log">
      <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
        <v-img src="@/assets/Logo-sams.png" class="mb-5" min-width="50%" max-width="80%"></v-img>
        <v-card center min-width="40%" max-width="100%">
          <p class="text-h5 white--text py-5 text-center" style="background-color:rgb(16, 0, 125)">
            Incio de Sesión
          </p>
          <v-card-text>
            <v-container>
              <v-form ref="form" @submit.prevent="validate" v-model="valid" lazy-validation>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-text-field v-model="usuario.usuario" :rules="nameRules" prepend-inner-icon="mdi-account-circle"
                      label="Usuario" outlined required></v-text-field>
                    <v-text-field :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
                      @click:append="show = !show" v-model="usuario.contrasena" :rules="contrasenaRules"
                      prepend-inner-icon="mdi-key" label="Contraseña" outlined required></v-text-field>
                  </v-col>
                </v-row>
                <v-alert v-model="alert" text type="error" dismissible border="top">
                  Usuario o contraseña incorrectos
                </v-alert>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" class="mr-4" @click="reset"> Cancelar</v-btn>
                  <v-btn :disabled="!valid" color="success" class="mr-4" type="submit"> Ingresar </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style>
  .login {
    height: 100vh;
  }

  .log {
    height: 100%;
  }
</style>

<script>
  import {mapActions} from "vuex";
  import {mapState} from "vuex";

  export default {
    name: "login",
    data: () => ({
      valid: true,
      show: false,
      alert: false,
      usuario: {
        usuario: "",
        contrasena: ""
      },

      nameRules: [
        (v) => !!v || "Usuario requerido",
      ],

      contrasenaRules: [
        (v) => !!v || "Contraseña requerida",
      ],
    }),

    computed: {
      ...mapState(["token"]),
    },

    methods: {
      ...mapActions(["login"]),

      validate() {
        if (this.$refs.form.validate()) {
          Promise.resolve(this.login(this.usuario)).then((value) => {
            if ((!value) || (value.token=="") || (value.token==null)) {
              this.alert = true;
            }
          })
        }
      },
      reset() {
        this.$refs.form.reset();
      },
    },
  };
</script>